import React  from 'react';
//Import CSS of Bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';

//Import CSS of React Toastify
import 'react-toastify/dist/ReactToastify.css';

//Import CSS of Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Import CSS of Owl Carousel
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.css';
//Import CSS for ow.carousel
// import 'owl.carousel/dist/assets/owl.carousel.css';

//Other Imports
import Routing from './Routes/Routing';


function App() {

  return (
    <>
      <Routing />
    </>
  );              
}

export default App;