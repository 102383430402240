import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import SectionOne from "../Components/SectionOne";
import SlickSliderTop from "../Components/SlickSliderTop";
import SlickSliderBottom from "../Components/SlickSliderBottom";
import OwlSlider from "../Components/OwlSlider";
import SectionTwo from "../Components/SectionTwo";
import SectionThree from "../Components/SectionThree";
import Footer from "../Components/Footer";
import Preloader from "../Components/Preloader";
import GetRequest from "../API/GetRequest";
import { getGamesApi } from "../Data/data";
import "../CSS/new.css";
import axios from "axios";
import Cookies from 'js-cookie'

const Home = () => {
 

  const navigate=useNavigate();

  const [response, setResponse] = useState(null);
  const msisdn =Cookies.get("msisdn")

  const checkUser = () => {
    axios.get(`http://91.205.172.123:5631/checkuser/${msisdn}`)
      .then((res) => {
        console.log("res==========", res.data.status);
        if (res.data.status == 0 ) {
          navigate('/login'); 
        } else {
          setResponse(res.data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setResponse('Error occurred while fetching data.');
      });
  };



  //To Call on Page Load
  useEffect(()=>{
    checkUser()
    getGamesFromBackend();
    // eslint-disable-next-line
  },[]);

  //To Go on any other page of project

  //Method to Call Games From Backend
  const getGamesFromBackend=()=>{
      const promise=GetRequest(getGamesApi);
      promise.then(e=>{
      // console.log("data ",e.response);

      if(e.message==='Network Error')
      {
        // console.log("Backend is Not Working");
        navigate("/error");
      }

      processGamesData(e.response);
      gettingThreeGames(e.response);
    })
  };

  //State to Store Games Data
  const[data,setData]=useState([]);

  //Setting Data in State
  const processGamesData=(games)=>{
    // console.log("games ",games);
    setData(games);
    setLoaderState(true);
    setDivState('none');
  }

  let count=0;

  //Hook to store three games array
  const[threeGamesArray,setThreeGamesArray]=useState([]);

  //Method to get Three Games randomly
  const gettingThreeGames=(games)=>{
    games.forEach(e=>{
      if(e.category==='mostPlayed')
      {
        // console.log("e ",e);
        let arr=e.game.splice(0,3);
        // console.log("arr ",arr);
        setThreeGamesArray(arr);
      }
    })
  }

  //Loader
  let Loader=require('react-loader');
  let options = {
    lines: 13,
    length: 30,
    width: 15,
    radius: 30,
    scale: 1.00,
    corners: 1,
    color: 'white',
    opacity: 0.85,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute'
  };

  //State for Loader
  const[loaderState,setLoaderState]=useState(false);

  //Hook to store starting div state(loading div)
  const[divState,setDivState]=useState('block');

  return (
    <>

  

      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      <Preloader/>
      <Header data={data}/>
     
      <main>
        <SectionOne data={data} />
        <SlickSliderTop />

        <div className="area-bg-one">

          {data.map((e,key)=>{
            count=count+1;
            // console.log(count);
            // console.log("e ",e);
            if(e.category==='mostPlayed')
              return(<span key={key}></span>)
            else
              return(
                <div key={key}>
                  <OwlSlider
                      list={e.game}
                      data={data}
                      key={key}
                      id={e.category}
                      name={e.category}
                    />
                    {count===2?<SectionTwo key={count} data={threeGamesArray}/>:<></>}
                    {count===4?<SectionThree list={data} key={count}/>:<></>}
                    {count===8?<SlickSliderBottom key={count}/>:<></>}
                </div>
              )
          })}
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Home;