import React, { useState } from 'react';
import logo from '../Media/Documentation/assets/img/janguKids.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie'
const Login = () => {
   const [phoneNumber, setPhoneNumber] = useState('');
   const navigate=useNavigate()
   
   const handleSubmit = async (event) => {

        event.preventDefault(); 
        const url = `http://91.205.172.123:5631/checkuser/${phoneNumber}`;
        
        console.log("=======")
        try {
            const response = await axios.get(url);
            console.log(response.data); 
            if(response.data.status==1){
              Cookies.set('msisdn', phoneNumber)
              navigate(`/`)
            }
            if(response.data.status==0){
                navigate('/login')
                setPhoneNumber('')
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
    };


   
 console.log("")

    return (
        <div className='bg-[#1C1421] h-screen'>
            <div className='container mx-auto flex justify-center py-5'>
                <figure className="max-w-lg">
                    <img className="h-auto max-w-full rounded-lg" src={logo} alt="JanguKids" />
                </figure>
            </div>
            <div className='container mx-auto flex justify-center'>
                <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <h5 className="text-3xl font-bold text-center text-gray-900" style={{ fontFamily: 'sans-serif' }}>Sign in to Jangukids</h5>
                        <div>
                            <label htmlFor="number" className="block mb-2 text-sm font-medium text-gray-900">Enter your number here</label>
                            <input
                                type="number"
                                name="number"
                                id="number"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="90*********"
                                required
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="w-full text-white bg-[#F81DA2] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
